<template>
  <div id="product-block">
    <div class="content-wrapper">
      <div
        class="content-container"
      >
        <div class="content">
          <div class="results">
            <router-link
              v-for="(product) in getResults"
              :key="product.id"
              :to="productRoute(product)"
              class="product-card-wrapper"
            >
              <ProductCard
                class="product-card"
                :product="product"
                :my-stock-room-product="true"
              />
            </router-link>
          </div>
        </div>
        <div
          v-if="(numberOfPages > 1 && productsCategory === 'pharma-products')"
          class="pagination-wrapper"
        >
          <BasePagination
            :pages="numberOfPages"
            :current-page="currentPage"
            @previous="prevPharmaPage"
            @next="nextPharmaPage"
            @page-clicked="setPharmaPage"
          />
        </div>

        <div
          v-if="(numberOfPages > 1 && productsCategory === 'medical-equipment')"
          class="pagination-wrapper"
        >
          <BasePagination
            :pages="numberOfPages"
            :current-page="currentPage"
            @previous="prevMedicalEquipmentPage"
            @next="nextMedicalEquipmentPage"
            @page-clicked="setMedicalEquipmentPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import BasePagination from '@/components/common/BasePagination.vue';
import ProductCard from '@/components/marketplace/common/ProductCard.vue';
import BasePagination from '@/components/common/BasePagination.vue';

export default {
  name: 'MarketplaceVendorProducts',
  components: {
    BasePagination,
    ProductCard,
  },
  props: {
    getResults: {
      type: Array,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
    productsCategory: {
      type: String,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
    }),
  },
  created() {
  },
  methods: {
    ...mapActions({
      nextPharmaPage: 'marketplaceVendor/nextPharmaPage',
      prevPharmaPage: 'marketplaceVendor/prevPharmaPage',
      setPharmaPage: 'marketplaceVendor/commitPharmaPage',

      nextMedicalEquipmentPage: 'marketplaceVendor/nextMedicalEquipmentPage',
      prevMedicalEquipmentPage: 'marketplaceVendor/prevMedicalEquipmentPage',
      setMedicalEquipmentPage: 'marketplaceVendor/commitMedicalEquipmentPage',
    }),
    productRoute(p) {
      const { vin, slug, categorySlug } = p;
      return {
        name: 'Product',
        params: { vin, slug, categorySlug },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card {
    width: 228px;
}
.loading {
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
}
.price-range {
  padding: 20px 30px 10px 30px;
  background: $white;
  border-radius: 12px;
}
.filter-label {
  font-size: .8em;
  margin: 0 -10px;
  color: $simpatra-blue;
}
.content-container {
  max-width: 1200px;
  margin: 0 auto;
}

.content {
  display: flex;
}
.results {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  width: min-content;
}
.product-card-wrapper {
  margin: 0px 0px 10px 10px;
  text-decoration: none;
}
.pagination-wrapper {
  margin-top: 25px;
  margin-bottom: 25px;
}
@media screen and (max-width: 1020px) {
  .results {
    margin-left: 0;
    justify-content: center;
  }
  .viewing {
    font-size: .8em;
  }
}
</style>
