<template lang="html">
  <div
    id="product-list"
  >
    <MarketplaceHeader
      v-if="productsCategory === '' || productsCategory === 'pharma-products'"
      :get-results="getPharamaProducts"
      :get-all-results="getAllPharamaProducts"
      :title="pharmaTitle"
      @vendorProductCategory="vendorProductCategory"
      @displayView="displayView"
    />

    <div v-if="getPharamaProducts.length > 0">
      <router-link
        v-for="(product, index) in getPharamaProducts"
        :key="product.id"
        :to="productRoute(product)"
        class="router-link"
      >
        <MyStockRoomProduct
          v-if="index < 4 && productsCategory === ''"
          :product="product"
        />
        <MyStockRoomProduct
          v-if="productsCategory === 'pharma-products'"
          :product="product"
        />
      </router-link>
    </div>
    <div
      v-if="getPharamaProducts.length === 0 && productsCategory === ''"
      class="no-data"
    >
      <BaseNoData
        class="empty"
      >
        Not found
      </BaseNoData>
    </div>

    <MarketplaceHeader
      v-if="productsCategory === '' || productsCategory === 'medical-equipment'"
      :get-results="getMedicalEquipmentsProducts"
      :get-all-results="getAllMedicalEquipmentsProducts"
      :title="medicalTitle"
      @vendorProductCategory="vendorProductCategory"
    />

    <div v-if="getMedicalEquipmentsProducts.length > 0">
      <router-link
        v-for="(product, index) in getMedicalEquipmentsProducts"
        :key="product.id"
        :to="productRoute(product)"
        class="router-link"
      >
        <MyStockRoomProduct
          v-if="index < 4 && productsCategory === ''"
          :product="product"
        />
        <MyStockRoomProduct
          v-if="productsCategory === 'medical-equipment'"
          :product="product"
        />
      </router-link>
    </div>
    <div
      v-if="getMedicalEquipmentsProducts.length === 0 && productsCategory === ''"
      class="no-data"
    >
      <BaseNoData
        class="empty"
      >
        Not found
      </BaseNoData>
    </div>

    <div
      v-show="(getNumberOfVendorPharmaProductsPages > 1 && productsCategory === 'pharma-products')"
      class="pagination-wrapper"
    >
      <BasePagination
        :pages="getNumberOfVendorPharmaProductsPages"
        :current-page="currentPharmaPage"
        @previous="prevPharmaPage"
        @next="nextPharmaPage"
        @page-clicked="setPharmaPage"
      />
    </div>

    <div
      v-show="(getNumberOfVendorMedicalEquipmentsPages > 1 && productsCategory === 'medical-equipment')"
      class="pagination-wrapper"
    >
      <BasePagination
        :pages="getNumberOfVendorMedicalEquipmentsPages"
        :current-page="currentMedicalEquipmentPage"
        @previous="prevMedicalEquipmentPage"
        @next="nextMedicalEquipmentPage"
        @page-clicked="setMedicalEquipmentPage"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MyStockRoomProduct from '@/components/marketplace/common/MyStockRoomProduct.vue';
// import BaseButton from '@/components/common/BaseButton.vue';
import BasePagination from '@/components/common/BasePagination.vue';
import BaseNoData from '@/components/common/BaseNoData.vue';
import MarketplaceHeader from '@/components/marketplace/common/MarketplaceHeader.vue';

export default {
  name: 'VendorStoreListView',
  components: {
    MyStockRoomProduct,
    // BaseButton,
    BasePagination,
    BaseNoData,
    MarketplaceHeader,
  },
  props: {
  },
  data() {
    return {
      loading: false,
      medicalTitle: 'Medical Equipment',
      bestSellingTitle: 'Best Selling Products',
      pharmaTitle: 'Pharmaceuticals',
      productsCategory: '',

      isSimilarSearch: false,
      recentlyView: false,
      bestSeller: false,
      similarProduct: false,
      gridIcon: false,
      listIcon: true,
      listView: false,
    };
  },
  computed: {
    ...mapGetters({
      getVendorId: 'getVendorId',
      // getBestSellingProducts: 'getVendorPharmaProductsPage',
      // getAllBestSellingProducts: 'getRecentViewPageResults',
      getAllMedicalEquipmentsProducts: 'marketplaceVendor/getVendorMedicalEquipments',
      getMedicalEquipmentsProducts: 'marketplaceVendor/getVendorMedicalEquipmentsPage',
      getPharamaProducts: 'marketplaceVendor/getVendorPharmaProductsPage',
      getAllPharamaProducts: 'marketplaceVendor/getVendorPharmaProducts',

      getNumberOfVendorPharmaProductsPages: 'marketplaceVendor/getNumberOfVendorPharmaProductsPages',
      getNumberOfVendorMedicalEquipmentsPages: 'marketplaceVendor/getNumberOfVendorMedicalEquipmentsPages',

      currentPharmaPage: 'marketplaceVendor/getPharmaPage',
      currentMedicalEquipmentPage: 'marketplaceVendor/getMedicalEquipmentPage',
    }),
  },
  methods: {
    ...mapActions({
      nextPharmaPage: 'marketplaceVendor/nextPharmaPage',
      prevPharmaPage: 'marketplaceVendor/prevPharmaPage',
      setPharmaPage: 'marketplaceVendor/commitPharmaPage',

      nextMedicalEquipmentPage: 'marketplaceVendor/nextMedicalEquipmentPage',
      prevMedicalEquipmentPage: 'marketplaceVendor/prevMedicalEquipmentPage',
      setMedicalEquipmentPage: 'marketplaceVendor/commitMedicalEquipmentPage',
    }),

    displayView(key) {
      if (key === 'list') {
        this.listView = true;
        this.gridView = false;
      }
      if (key === 'grid') {
        this.listView = false;
        this.gridView = true;
      }
    },

    vendorProductCategory(category) {
      this.productsCategory = category;
    },

    productRoute(p) {
      const { vin, slug, categorySlug } = p;
      return {
        name: 'Product',
        params: { vin, slug, categorySlug },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
#product-list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.no-data {
  padding: 20px;
}
.empty {
  height: 200px;
}
.view-all-active {
  // margin-top: -34px;
}
.weighted {
    font-weight: 600;
  }
.viewing {
    padding: 8px 0px 0px 0px;
    margin-left: auto;
  }

  .viewing-recent-view {
    padding: 25px 0px 0px 0px;
    margin-left: auto;
  }
.pagination-wrapper {
  margin-top: 25px;
  margin-bottom: 25px;
}
.back-button {
    // margin-left: auto;
    // margin-right: 15px;;
}
.el-icon {
  margin-left: 10px;
  // padding: 18px 0px 0px 10px;
}
.header {
  display: flex;
}
.active-icon {
  color: $orange;
}
.in-active-icon {
  color: lightgrey;
}
.grid-icon {
  border-right: 1px solid lightgrey;
  cursor: pointer;
}
.list-icon {
  padding-left: 5px;
  cursor: pointer;
}
.router-link {
  text-decoration: none;
}
.title {
  display: flex;
  padding: 20px;
}
.view-label {
    margin-left: auto;
    margin-right: 20px;
}

.recent-view-label {
    margin-top: 25px;
    margin-left: auto;
    margin-right: 20px;
 }
 .view-list {
    font-size: 14px;
    color: $orange;
    cursor: pointer;
}
.qty-input-container {
  display: flex;
  width: 140px;
  margin-left: 15px;
  justify-content: flex-end;
}
.qty-update {
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f05a28;
  opacity: 5;
  transition: all .2s ease;
  margin: 0px 5px;
}
.subtotal-wrapper {
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
}
.subtotal {
  font-weight: 600;
  padding: 5px 10px;
  background-color: #ebeef5;
  border-radius: 8px;
}
.price, .qty, .subtotal {
  font-size: 16px;
  color: dark-blue;
}
.vl {
  border-left: 1px solid #989898;;
  height: 50px;
  margin-right: 10px;
}
.order-date {
    font-size: 14px;
    color: #989898;
}
.price {
    font-weight: 600;
}
/* .right {
    padding-top: 20px;
} */
.right {
  display: flex;
  align-items: center;
}
.order-id {
    font-size: 16px;
    font-weight: 600;
    color: #989898;
    padding: 5px;
 }
 .product-img {
     width: 100px;
 }
 .product-name {
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
        font-size: 14px;
 }
 .product-info-container {
     display: flex;
 }
 .order-details {
     margin-left: 20px;
     font-size: 14px;
 }
</style>
