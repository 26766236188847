<template>
  <div id="vendor-store">
    <div class="actions">
      <LayoutCentered class="layout">
        <VendorStoreGridView v-if="gridView" />
        <VendorStoreListView v-if="displayListView" />
      </LayoutCentered>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LayoutCentered from '@/components/common/LayoutCentered.vue';
import VendorStoreGridView from '@/components/dashboard/vendor/VendorStoreGridView.vue';
import VendorStoreListView from '@/components/dashboard/vendor/VendorStoreListView.vue';

export default {
  name: 'VendorStore',
  components: {
    LayoutCentered,
    VendorStoreGridView,
    VendorStoreListView,
  },
  props: {
    // Route Prop
  },
  data() {
    return {
      loading: false,
      gridView: true,
      displayListView: false,
    };
  },
  computed: {
    ...mapGetters({
    }),
  },
  async mounted() {
    this.$root.$on('displayGridView', (result) => {
      this.gridView = result;
      this.displayListView = false;
    });
    this.$root.$on('displayListView', (result) => {
      this.gridView = false;
      this.displayListView = result;
      this.$root.$emit('displayList', true);
    });
    await this.fetchVendorPharmaProducts();
    await this.fetchVendorMedicalEquipments();
  },
  methods: {
    ...mapActions({
      fetchVendorPharmaProducts: 'marketplaceVendor/fetchVendorPharmaProducts',
      fetchVendorMedicalEquipments: 'marketplaceVendor/fetchVendorMedicalEquipments',
    }),
  },
};
</script>

<style lang="scss" scoped>

.actions {
    background-color: #f5f7fa;
    padding-top: 50px;
    padding-bottom: 50px;
}
</style>
