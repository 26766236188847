<template>
  <div
    id="vendor-store-grid"
  >
    <div class="actions">
      <LayoutCentered class="layout">
        <MarketplaceHeader
          v-if="productsCategory === '' || productsCategory === 'pharma-products'"
          :get-results="getPharamaProducts"
          :get-all-results="getAllPharamaProducts"
          :title="pharmaTitle"
          @vendorProductCategory="vendorProductCategory"
          @displayView="displayView"
        />
        <MarketplaceCarousel
          v-if="productsCategory === '' && getPharamaProducts.length > 0"
          :carousel-products="getAllPharamaProducts"
        />
        <MarketplaceVendorProducts
          v-if="productsCategory === 'pharma-products'"
          :get-results="getPharamaProducts"
          :number-of-pages="getNumberOfVendorPharmaProductsPages"
          :products-category="productsCategory"
          :current-page="currentPharmaPage"
        />
        <div
          v-if="getPharamaProducts.length === 0 && productsCategory === ''"
          class="no-data"
        >
          <BaseNoData
            class="empty"
          >
            Not found
          </BaseNoData>
        </div>

        <MarketplaceHeader
          v-if="productsCategory === '' || productsCategory === 'medical-equipment'"
          :get-results="getMedicalEquipmentsProducts"
          :get-all-results="getAllMedicalEquipmentsProducts"
          :title="medicalTitle"
          @vendorProductCategory="vendorProductCategory"
        />
        <MarketplaceCarousel
          v-if="productsCategory === '' && getMedicalEquipmentsProducts.length > 0"
          :carousel-products="getMedicalEquipmentsProducts"
        />
        <MarketplaceVendorProducts
          v-if="productsCategory === 'medical-equipment'"
          :get-results="getMedicalEquipmentsProducts"
          :number-of-pages="getNumberOfVendorMedicalEquipmentsPages"
          :products-category="productsCategory"
          :current-page="currentMedicalEquipmentPage"
        />
        <div
          v-if="getMedicalEquipmentsProducts.length === 0 && productsCategory === ''"
          class="no-data"
        >
          <BaseNoData
            class="empty"
          >
            Not found
          </BaseNoData>
        </div>
      </LayoutCentered>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MarketplaceCarousel from '@/components/marketplace/common/MarketplaceCarousel.vue';
import LayoutCentered from '@/components/common/LayoutCentered.vue';
import MarketplaceHeader from '@/components/marketplace/common/MarketplaceHeader.vue';
import MarketplaceVendorProducts from '@/components/dashboard/vendor/MarketplaceVendorProducts.vue';
import BaseNoData from '@/components/common/BaseNoData.vue';

export default {
  name: 'VendorStoreGridView',
  components: {
    BaseNoData,
    MarketplaceCarousel,
    LayoutCentered,
    MarketplaceHeader,
    MarketplaceVendorProducts,
  },
  props: {
    // Route Prop
  },
  data() {
    return {
      loading: false,
      medicalTitle: 'Medical Equipment',
      bestSellingTitle: 'Best Selling Products',
      pharmaTitle: 'Pharmaceuticals',
      productsCategory: '',
      listView: false,
      gridView: true,
    };
  },
  computed: {
    ...mapGetters({
      getNumberOfVendorPharmaProductsPages: 'marketplaceVendor/getNumberOfVendorPharmaProductsPages',
      getNumberOfVendorMedicalEquipmentsPages: 'marketplaceVendor/getNumberOfVendorMedicalEquipmentsPages',

      getVendorId: 'marketplaceVendor/getVendorId',
      // getBestSellingProducts: 'getVendorPharmaProductsPage',
      // getAllBestSellingProducts: 'getRecentViewPageResults',
      getAllMedicalEquipmentsProducts: 'marketplaceVendor/getVendorMedicalEquipments',
      getMedicalEquipmentsProducts: 'marketplaceVendor/getVendorMedicalEquipmentsPage',
      getPharamaProducts: 'marketplaceVendor/getVendorPharmaProductsPage',
      getAllPharamaProducts: 'marketplaceVendor/getVendorPharmaProducts',

      currentPharmaPage: 'marketplaceVendor/getPharmaPage',
      currentMedicalEquipmentPage: 'marketplaceVendor/getMedicalEquipmentPage',
    }),
  },
  methods: {
    ...mapActions([
    ]),
    vendorProductCategory(category) {
      this.productsCategory = category;
    },
    displayView(key) {
      if (key === 'gird') {
        this.gridView = true;
        // this.listView = false;
      }

      if (key === 'list') {
        this.listView = true;
        this.gridView = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
    height: 200px;
  }

.no-data {
    padding: 20px
  }
</style>
